body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #b2f4e9fe;
}
header, nav, section, footer {
  padding: 20px;
}


/* Responsive Design */
@media screen and (max-width: 768px) {
  .home h2 {
    font-size: 2rem;
  }

  .home p {
    font-size: 1rem;
  }

  .product-card {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    width: 90%;
  }
}