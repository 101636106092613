/* Contact.css */

#contact {
    background-color: #f9f9f9;
    padding: 2rem;
    max-width: 600px;
    margin: 2rem auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  #contact h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  #contact form {
    display: flex;
    flex-direction: column;
  }
  
  #contact label {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  #contact input,
  #contact textarea {
    font-size: 1rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  #contact input:focus,
  #contact textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  #contact textarea {
    resize: none;
    min-height: 100px;
  }
  
  #contact button {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #contact button:hover {
    background-color: #0056b3;
  }
  
  #contact address {
    font-size: 0.9rem;
    color: #666;
    margin-top: 1.5rem;
    line-height: 1.6;
    text-align: center;
  }
  
  #contact address a {
    color: #007bff;
    text-decoration: none;
  }
  
  #contact address a:hover {
    text-decoration: underline;
  }
  