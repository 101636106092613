/* Navigation Bar */
.nav {
    background-color: #444;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center; /* Center the items */
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

/* Navigation Links */
.nav a {
    color: #fff;
    text-decoration: none;
    padding: 12px 25px;
    margin: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
    font-size: 1rem; /* Use relative units for scalability */
}

.nav a:hover {
    background-color: #555;
}

/* Responsive Design: Tablets */
@media screen and (max-width: 768px) {
    .nav {
        padding: 10px; /* Reduced padding on tablets */
        flex-direction: column; /* Stack the navigation links vertically */
    }

    .nav a {
        font-size: 1.1rem; /* Slightly larger font on tablets */
        padding: 10px 20px; /* Adjust padding for better spacing */
        margin: 5px 0; /* Reduced margin to suit the stacked layout */
    }
}

/* Responsive Design: Mobile Phones */
@media screen and (max-width: 480px) {
    .nav {
        padding: 8px; /* Even less padding on mobile */
    }

    .nav a {
        font-size: 1rem; /* Smaller font on mobile */
        padding: 8px 15px; /* Adjust padding for better spacing */
        margin: 5px 0; /* Spacing for mobile */
    }
}
