/* Header */
.header {
    background-color: #285975fe;
    color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; /* Allows wrapping of items for smaller screens */
}

/* Logo */
.logo {
    max-width: 100px; /* Adjust logo size for smaller screens */
    height: auto;
    margin-right: 20px;
    border-radius: 10%;
    transition: transform 0.3s ease-in-out;
}

.logo:hover {
    transform: scale(1.1); /* Adds a slight zoom effect on hover */
}

/* Title */
.title {
    font-family: 'Times New Roman', serif;
    font-size: 2rem; /* Use relative units (rem) instead of px for responsiveness */
    font-weight: bold;
    margin: 0;
    letter-spacing: 1px;
    flex-grow: 1; /* Makes sure the title takes up available space */
}

/* Responsive Design: Tablets */
@media screen and (max-width: 768px) {
    .header {
        flex-direction: column;
        text-align: center;
        padding: 15px; /* Reduced padding for smaller screens */
    }

    .logo {
        max-width: 90px; /* Further reduce logo size on tablets */
        margin-right: 0;
        margin-bottom: 10px; /* Add space below the logo */
    }

    .title {
        font-size: 1.8rem; /* Slightly smaller title on tablets */
    }
}

/* Responsive Design: Mobile Phones */
@media screen and (max-width: 480px) {
    .header {
        padding: 10px; /* Even less padding on mobile */
    }

    .logo {
        max-width: 80px; /* Reduce logo size even further on mobile */
        margin-bottom: 10px; /* Space below the logo */
    }

    .title {
        font-size: 1.5rem; /* Smaller title on mobile */
    }
}
